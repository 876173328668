import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import hostImg from '../../assets/img/host-landing/cover-image.jpg';
import sniffPub from '../../assets/img/sniff-vs-pub-2.jpg';
import { TOP_CITIES } from '../../consts/top-cities';
import { SplideSlide } from '@splidejs/react-splide';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { escapeString } from '../../helpers/escape-string';
import { RouteFormatter } from '../../routes';
import { SF_SIZE_OPTIONS } from '../../consts/search-filter.const';
import { HOME_FAQS_CONTENT } from '../../consts/home.consts';
import { cityLink, stateLink, toColumns } from '../../helpers/column-view';
import { LISTING_WORK_CONTENT, LISTING_WORK_CONTENT_MBL } from '../../consts/listings.consts';
import RecommendedByExperts from '../../components/recommended-by-experts';
import RenderOtherTypesSSR from '../listings/render-other-types-ssr';
import RenderAppDownload from '../../components/render-app-download';
import RenderOtherTypes from '../listings/render-other-types';
import { ImageHelper } from '../../classes/image-helper.class';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import useGetHomePage from '../../services/hooks/useGetHomePage';
import BlogCategories from '../../components/blog-categories';
import { sniffDesc } from '../../consts/seo.consts';
import RenderStatus from './render-status';
import SniffAsSeen from '../../components/sniif-as-seen';
import SlideMobile from '../../components/slide-mobile';
import SnifSection from '../../components/snif-section';
import SniffButton from '../../components/sniff-button';
import PictureSet from '../../components/picture-set';
import ReviewCard from '../../components/review-card';
import PreLoader from '../../components/preloader';
import SnifHr from '../../components/snif-hr';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgCircleCheck from 'src/assets/svg/SvgCircleCheck.svg';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgRuler from 'src/assets/svg/SvgRuler.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';

const b = bemCn('home');

const HOSTING_BENEFITS = [
    'Help dogs that need private space',
    'Earn passive income',
    'Supplement and expand pet sitting or training business',
    "It's free to list with no obligation",
    'Safety first - vaccinations required, waiver, $1M Host Protection Insurance',
];

const RenderCTA = () => (
    <div className={b('cta')}>
        <a href={RouteFormatter.listings({})}>
            <SniffButton size="lg" block="mobile">
                Explore spots near me
            </SniffButton>
        </a>
    </div>
);

const RenderSubType = ({ icon, onClick, title }) => (
    <div className="sub-types-item" onClick={onClick}>
        <div className="types-item-left">
            {icon}
            <div className="types-item-left-right">
                <div className="snif-m2 snif-semibold mb-0">{title}</div>
            </div>
        </div>
        <SvgChevronRight className="types-icon-right" />
    </div>
);

const RenderExp = ({ title, sub }) => (
    <div className="exp-item">
        <SvgCircleCheck />
        <div className="exp-item-right">
            <div className="snif-p snif-semibold mb-1">{title}</div>
            <div className="snif-p text-deep">{sub}</div>
        </div>
    </div>
);

const RenderBenefit = ({ text }) => (
    <div className="hosting-benefit">
        <SvgCircleCheck />
        <div className="snif-p">{text}</div>
    </div>
);

const RenderClientReviewComp = ({ homePage, useEffectSSR, useStateSSR, useRefSSR }) => (
    <SlideMobile>
        {homePage?.recentReviews?.map((r, i) => (
            <SplideSlide key={i}>
                <ReviewCard review={r} useState={useStateSSR} useEffect={useEffectSSR} useRef={useRefSSR} />
            </SplideSlide>
        ))}
    </SlideMobile>
);

const RenderServerReviewComp = ({ homePage, useEffectSSR, useStateSSR, useRefSSR }) => (
    <div className="reviews-box">
        {homePage?.recentReviews?.map((r, i) => (
            <ReviewCard key={i} review={r} useState={useStateSSR} useEffect={useEffectSSR} useRef={useRefSSR} />
        ))}
    </div>
);

const RenderDetailedHome = ({
    states,
    handleSubClick,
    handleExplore,
    handleSubStatsClick,
    SSR,
    homePageSSR,
    useStateSSR,
    useEffectSSR,
    useRefSSR,
    serverSSR,
}) => {
    const isMobile = useMobileScreen();
    let homePage = homePageSSR;
    let loading = false;
    const [colCount, setColCount] = useState(2);
    const [sniffWorks, setSniffWorks] = useState(LISTING_WORK_CONTENT_MBL);
    const [ReviewComponent, setReviewComponent] = useState(<RenderServerReviewComp {...{ homePage, useEffectSSR, useStateSSR, useRefSSR }} />);

    if (!SSR) {
        const { homePage: hp, loading: ld } = useGetHomePage();
        homePage = hp;
        loading = ld;
    }

    const goToBigSize = () => handleSubClick(null, { showSize: true, minimumSize: SF_SIZE_OPTIONS[1] });
    const goToFullFenced = () => handleSubClick(null, { showFencing: true });
    const goToNoDogsPresent = () => handleSubClick(null, { dogsPresent: true });

    useEffect(() => {
        if (isMobile) {
            setReviewComponent(<RenderClientReviewComp {...{ homePage, useEffectSSR, useStateSSR, useRefSSR }} />);
        } else {
            setSniffWorks(LISTING_WORK_CONTENT);
            setColCount(5);
        }
    }, [isMobile]);

    return (
        <>
            <div className={b('other-types')}>{SSR ? <RenderOtherTypesSSR /> : <RenderOtherTypes />}</div>
            <SnifSection>
                <div className={b('sub-types')}>
                    <RenderSubType icon={<SvgFenced />} onClick={goToFullFenced} title="Fully fenced dog parks near me" />
                    <RenderSubType icon={<SvgRuler />} onClick={goToBigSize} title="1/2+ acre dog parks near me" />
                    <RenderSubType icon={<SvgDog />} onClick={goToNoDogsPresent} title="Dog parks near me with no other dogs audible/visible" />
                </div>
            </SnifSection>
            <SnifSection>
                <div className={b('experience')}>
                    <h2 className="snif-m1 snif-l1-pc snif-semibold mb-2">The best experience and fun for you and your dog</h2>
                    <p className="snif-p mb-4">Our dog parks are designed for the ultimate safe dog exercise</p>
                    <div className="exp-container">
                        <RenderExp
                            title="Distraction free dog time"
                            sub="Safe, private spaces to help you minimize distractions or triggers and maximize time with your dog."
                        />
                        <RenderExp
                            title="Off leash enrichment"
                            sub="Exploration and activities you can't get anywhere else: wear your dog out for days."
                        />
                        <RenderExp
                            title="Safe playdates"
                            sub="Socialize your pup in a controlled, private spot - it's great exercise, it's safe and it's fun for you and your dog."
                        />
                    </div>
                </div>
                <RenderCTA {...{ SSR }} />
            </SnifSection>
            <SnifSection>
                <SniffAsSeen {...{ serverSSR }} isMobileSSR={isMobile} useState={useStateSSR} margin={0} />
            </SnifSection>
            <div className={b('how-works')}>
                <SnifSection>
                    <h2 className="snif-m1 snif-l1-pc snif-semibold mb-4">How do Sniffspot's private dog parks work?</h2>
                    <div className="snif-work-list">
                        {sniffWorks.map((L, i) => (
                            <div key={i} className="work-list-item">
                                <L.Icon />
                                <div>
                                    <h2 className="snif-p snif-semibold mb-1">{L.title}</h2>
                                    <div className="snif-p">
                                        <L.desc />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <RenderCTA {...{ SSR }} />
                </SnifSection>
            </div>
            {loading ? (
                <SnifSection>
                    <PreLoader full />
                </SnifSection>
            ) : (
                <>
                    <RenderStatus {...{ data: homePage, handleExplore, handleSubStatsClick }} />
                    <div className="mx-2" style={{ marginTop: '-64px' }}>
                        <RenderCTA {...{ SSR }} />
                    </div>
                    <SnifSection>
                        <h2 className="snif-m1 snif-l1-pc snif-semibold mb-2 mb-md-4">Recommended by experts</h2>
                        <RecommendedByExperts {...{ useStateSSR, SSR }} />
                    </SnifSection>
                    <SnifSection>
                        <div className={b('blog')}>
                            <h2 className="snif-m1 snif-l1-pc snif-semibold mb-4">Check out our blog!</h2>
                            <h3 className="snif-m2 snif-m1-pc snif-bold mb-3 mb-md-4">Top dog guides per area</h3>
                            <BlogCategories categories={homePage?.allCategories} />
                            <h3 className="snif-m2 snif-m1-pc snif-bold mt-5 mb-3 mb-md-4">Most recent articles</h3>
                            <div className="blog-recent-post-box">
                                {homePage?.recentPosts?.map((p, i) => {
                                    const thumbnail = p.thumbnail
                                        ? ImageHelper.getUrlFromOptions(p.thumbnail, `full-fit-in/${IMG_RESIZES.x768}`)
                                        : sniffPub;
                                    return (
                                        <a
                                            href={`https://www.sniffspot.com${p.slug}`}
                                            key={i}
                                            className="blog-recent-post"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div key={i}>
                                                <PictureSet imgs={thumbnail} alt={p.title} SSR={SSR} useState={useStateSSR} />
                                                <p className="snif-p snif-m2-pc snif-semibold">{p.title}</p>
                                            </div>
                                        </a>
                                    );
                                })}
                            </div>
                            <div className="text-md-end">
                                <a href={RouteFormatter.blog()}>
                                    <SniffButton className="mt-4" size="lg">
                                        See all blog posts
                                    </SniffButton>
                                </a>
                            </div>
                            <h3 className="snif-m2 snif-m1-pc snif-bold mb-3 mb-md-4 mt-4">Most popular articles</h3>
                            <div className="blog-recent-post-box">
                                {homePage?.popularPosts?.map((p, i) => {
                                    const thumbnail = p.thumbnail
                                        ? ImageHelper.getUrlFromOptions(p.thumbnail, `full-fit-in/${IMG_RESIZES.x768}`)
                                        : sniffPub;

                                    return (
                                        <a
                                            href={`https://www.sniffspot.com${p.slug}`}
                                            key={i}
                                            className="blog-recent-post"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div key={i}>
                                                <PictureSet imgs={thumbnail} alt={p.title} SSR={SSR} useState={useStateSSR} />
                                                <p className="snif-p snif-m2-pc snif-semibold">{p.title}</p>
                                            </div>
                                        </a>
                                    );
                                })}
                            </div>
                            <div className="text-md-end">
                                <a href={RouteFormatter.blog()}>
                                    <SniffButton className="mt-4" size="lg">
                                        See all blog posts
                                    </SniffButton>
                                </a>
                            </div>
                        </div>
                    </SnifSection>
                    <div className={b('app-download')}>
                        <SnifSection>
                            <RenderAppDownload />
                        </SnifSection>
                    </div>
                    <SnifSection>
                        <div className={b('reviews')}>
                            <h2 className="snif-m1 snif-l1-pc snif-semibold mb-3 mb-md-5">What people say about our private dog parks</h2>
                            {ReviewComponent}
                        </div>
                        <RenderCTA {...{ SSR }} />
                    </SnifSection>
                </>
            )}
            <div className={b('faqs')}>
                <SnifSection>
                    <h2 className="snif-m1 snif-l1-pc snif-semibold mb-4">Frequently asked questions about Sniffspot's private dog parks</h2>
                    <div className="faqs-listings-box" itemScope itemType="https://schema.org/FAQPage">
                        {HOME_FAQS_CONTENT.map((F, i) => (
                            <div key={i} className="faqs-listings-item" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                                <h3 className="snif-m2 snif-semibold mb-2" itemProp="name">
                                    {F.que}
                                </h3>
                                <div className="snif-p" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                                    <div itemProp="text">
                                        <F.ans />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <RenderCTA {...{ SSR }} />
                </SnifSection>
            </div>
            <SnifSection>
                <h2 className="snif-l1-pc snif-semibold mb-5 d-none d-md-block">Got land? Make up to $3,000 per month while helping dogs!</h2>
                <div className={b('hosting')}>
                    <PictureSet
                        imgs={hostImg}
                        className="cover-img"
                        alt="Become a Host - Help Dogs And Make Money With Your Yard"
                        SSR={SSR}
                        useState={useStateSSR}
                    />
                    <h2 className="snif-m1 snif-semibold mb-3 d-md-none">Got land? Make up to $3,000 per month while helping dogs!</h2>
                    <div>
                        {HOSTING_BENEFITS.map((t, i) => (
                            <RenderBenefit key={i} text={t} />
                        ))}
                        <a href={RouteFormatter.hostLanding()}>
                            <SniffButton size="lg">Learn more about hosting</SniffButton>
                        </a>
                    </div>
                </div>
            </SnifSection>
            <SnifHr />
            <SnifSection>
                <h2 className="snif-m2 snif-l1-pc snif-semibold mb-1 mb-md-3">About Sniffspot</h2>
                <div className="snif-s2 snif-p-pc">{sniffDesc}</div>
                <h2 className="snif-m2 snif-medium mt-4 mt-md-5 mb-2">Browse by top cities</h2>
                <div className="d-flex justify-content-between medium-gray-color">
                    {toColumns(TOP_CITIES, colCount).map((col, i) => (
                        <div key={i}>{col.map((c, j) => cityLink(c.city, c.url, j, SSR))}</div>
                    ))}
                </div>
            </SnifSection>
            <SnifHr />
            <SnifSection>
                <h2 className="snif-m2 snif-medium mb-2">Browse by state</h2>
                <div className="d-flex justify-content-between medium-gray-color">
                    {toColumns(states, colCount).map((col, i) => (
                        <div key={i}>{col.map((c, j) => stateLink(c, escapeString(c), j, SSR))}</div>
                    ))}
                </div>
            </SnifSection>
            <div className={b('explore')}>
                <SnifSection>
                    <h2 className="snif-m1 snif-l2-pc snif-semibold mb-4">Rent safe and private dog parks hosted by locals</h2>
                    <a href={RouteFormatter.listings({})}>
                        <SniffButton size="lg" block="mobile">
                            Explore spots near me
                        </SniffButton>
                    </a>
                </SnifSection>
            </div>
        </>
    );
};

export default RenderDetailedHome;
